import { ModuleDetails, ModulesList } from "../models";

export const modulesList: ModulesList = {
  message: "success",
  error: null,
  data: [
    {
      id: 5,
      name: "Module name 1",
    },
    {
      id: 6,
      name: "Model name 2",
    },
    {
      id: 7,
      name: "Module name 3",
    },
    {
      id: 8,
      name: "Module name 4",
    },
    {
      id: 9,
      name: "module Name",
    },
    {
      id: 10,
      name: "module Name",
    },
    {
      id: 11,
      name: "module Name",
    },
    {
      id: 12,
      name: "module Name",
    },
    {
      id: 13,
      name: "my new module",
    },
    {
      id: 14,
      name: "my new module",
    },
  ],
};

export const modulesDetails: ModuleDetails = {
  message: "success",
  error: null,
  data: {
    id: 2,
    name: "Module name 2  f",
    total_minutes_video: 1,
    total_minutes_reading: 12,
    total_number_quizs: 34,
    total_minutes_quizs: 3,
    description: "Description Goes Here",
    objectives: {
      objOne: "Objective One",
      objTwo: "Objective Two",
    },
    submodule: [
      {
        id: 6,
        name: "SubM One F",
      },
      {
        id: 7,
        name: "SubM Two F",
      },
      {
        id: 11,
        name: "SubM Three F",
      },
    ],
    stage: 1,
  },
};
